import { cloneDeep } from 'lodash'
import SVG from 'svg.js'
import { Viewport, bound, viewportToBoundingBox, BB, sleep } from '../utils'
import * as Commands from '../constants/commands'
import { toCSSRGB } from '../graphics/color'
import Node from '../cgp/Node'
import Network, { MEMORY_VALUE_MAX } from '../cgp/Network'
import {
  VariableValues,
  Memory,
  VariableAddresses,
  CGPVariableAddress,
  VariableValue,
  NodeAddress,
} from '../cgp/types'
import {
  Palette,
  ChannelAddress,
  Color,
  Channel,
  GradientType,
} from '../graphics/color.types'
import { appMode } from '../settings'
import { CommandId } from '../cgp/commands'
import {
  rect,
  circle,
  triangle,
  MARGIN_SPACE,
  MARGIN_LINE,
  rhomb,
  smallestDim,
} from './canvas'

export interface State {
  // basically used by a stack
  bb: BB
}

type Direction = 'vertical' | 'horizontal'

const VERTICAL: Direction = 'vertical'
const HORIZONTAL: Direction = 'horizontal'

const value = (variables: VariableValues, index: number, max: number): number =>
  Math.round((variables[index] / MEMORY_VALUE_MAX) * max)

interface GlobalSettings {
  minSplit: number
  maxSplit: number
}

export type OnBeatFunction = (index: number, command: CommandId | undefined, currentColor: Color | undefined) => void

export default class Parser {
  private aborted = false
  private readonly svg: SVG.Doc
  private readonly grid: Network
  public onBeat: OnBeatFunction = () => undefined
  private memory: Memory
  private readonly palette: Palette
  private readonly drawingSize: Viewport
  private readonly globalSettings: GlobalSettings
  private readonly slow: boolean = false

  constructor(
    grid: Network,
    svg: SVG.Doc,
    drawingSize: Viewport,
    globalSettings: GlobalSettings = { minSplit: 0, maxSplit: 4 },
  ) {
    this.grid = grid
    this.svg = svg
    this.memory = cloneDeep(this.grid.memory!) // eslint-disable-line @typescript-eslint/no-non-null-assertion
    this.palette = cloneDeep(this.grid.palette!) // eslint-disable-line @typescript-eslint/no-non-null-assertion
    this.drawingSize = drawingSize
    this.globalSettings = globalSettings
    if (appMode === 'network') {
      this.slow = true
    }
  }

  lookupValues(variables: VariableAddresses): VariableValues {
    return variables.map(
      (address: CGPVariableAddress): VariableValue => this.memory[address],
    ) as VariableValues
  }

  incrementMemory(node: Node, amount: number, registerIndex: number): void {
    const oldValue = this.memory[node.variables[registerIndex]]
    const newValue = oldValue + amount * 2
    this.memory[node.variables[registerIndex]] = bound(
      newValue,
      0,
      MEMORY_VALUE_MAX,
    )
  }

  colorByPaletteIndex(paletteIndex: number): Color {
    if (paletteIndex >= this.palette.length) throw new Error('not enough colours')
    const colorPointer = this.palette[paletteIndex]
    const result = colorPointer.map(
      (channelAddress: ChannelAddress): Channel =>
        (this.memory[channelAddress] / MEMORY_VALUE_MAX) * 255,
    )
    return result as Color
  }

  currentColor(node: Node): Color {
    const paletteIndex = Math.round(
      (this.memory[node.variables[0]] / MEMORY_VALUE_MAX) *
        (this.palette.length - 1),
    )
    const colorPointer = this.palette[paletteIndex]
    const result = colorPointer.map(
      (channelAddress: ChannelAddress): Channel =>
        (this.memory[channelAddress] / MEMORY_VALUE_MAX) * 255,
    )
    return result as Color
  }

  async decodeSplit(
    index: NodeAddress,
    variables: VariableValues,
    state: State,
    node: Node,
    takeFirst: boolean,
  ): Promise<void> {
    let direction: Direction | null = null
    direction =
      state.bb[3] - state.bb[1] > state.bb[2] - state.bb[0]
        ? VERTICAL
        : HORIZONTAL
    const maxSplit =
      value(
        variables,
        this.globalSettings.minSplit,
        this.globalSettings.maxSplit,
      ) + 1 // 4
    const count = value(variables, 1, maxSplit) + 1
    const continueIndex = value(variables, 2, maxSplit)
    const traverseOrder = variables[1] > MEMORY_VALUE_MAX / 2
    return await this.split(
      index,
      direction,
      count,
      state,
      node,
      takeFirst,
      continueIndex,
      traverseOrder,
    )
  }

  async split(
    parentIndex: NodeAddress,
    direction: Direction,
    count: number,
    state: State,
    node: Node,
    takeFirst: boolean,
    continueIndex: number | null,
    traverseOrder: boolean,
  ): Promise<void> {
    const size =
      direction === HORIZONTAL
        ? (state.bb[2] - state.bb[0]) / count
        : (state.bb[3] - state.bb[1]) / count

    for (let i = 0; i < count; i++) {
      const index = traverseOrder ? i : count - i - 1
      const bb: BB =
        direction === HORIZONTAL
          ? [
              state.bb[0] + size * index,
              state.bb[1],
              state.bb[0] + size * (index + 1),
              state.bb[3],
            ]
          : [
              state.bb[0],
              state.bb[1] + size * index,
              state.bb[2],
              state.bb[1] + size * (index + 1),
            ]

      const newState: State = { ...state, bb }

      if (continueIndex !== null && index === continueIndex) {
        await this.drawNode(node.next[1], newState)
      } else {
        const next = node.next[takeFirst ? 0 : index]
        await this.drawNode(next, newState)
      }
    }
  }

  async drawWindow(
    parentIndex: NodeAddress,
    state: State,
    node: Node,
    takeCenter: boolean,
    gradientDirection: boolean,
    strokeOnly: boolean,
    gradientDimFactor: number,
    gradientType: GradientType,
  ): Promise<void> {
    const centerBB: BB = rhomb(
      state.bb,
      this.currentColor(node), // , this.grid),
      this.svg,
      gradientDirection,
      strokeOnly,
      gradientDimFactor,
      gradientType,
    )
    const nextState: State = { ...state, bb: takeCenter ? centerBB : state.bb }
    const next = node.next[0]
    await this.drawNode(next, nextState)
  }

  drawLines(state: State, color: Color, variables: VariableValues): void {
    const { bb } = state
    const smallestDimension = smallestDim(bb)
    if (smallestDimension < 4) return
    const MAX_DISTANCE = 25
    const lineDistance = ((variables[1] / MEMORY_VALUE_MAX) * MAX_DISTANCE) << 0
    const direction: Direction =
      variables[2] > variables[0] ? HORIZONTAL : VERTICAL

    const count =
      direction === VERTICAL
        ? ((bb[3] - bb[1]) / lineDistance) << 0
        : ((bb[2] - bb[0]) / lineDistance) << 0

    for (let i = 0; i < count; i++) {
      let line = null
      if (direction === VERTICAL) {
        const y = bb[1] + (i + 0.5) * lineDistance
        line = this.svg.line(bb[0], y, bb[2], y)
      } else {
        const x = bb[0] + (i + 0.5) * lineDistance
        line = this.svg.line(x, bb[1], x, bb[3])
      }

      line.stroke({ width: 0.1 }).attr({ stroke: toCSSRGB(color) })
    }
  }

  async margin(
    index: NodeAddress,
    margin: number,
    node: Node,
    state: State,
  ): Promise<void> {
    const nextState: State =
      state.bb[0] + margin < state.bb[2] - margin &&
      state.bb[1] + margin < state.bb[3] - margin
        ? {
            ...state,
            bb: [
              state.bb[0] + margin,
              state.bb[1] + margin,
              state.bb[2] - margin,
              state.bb[3] - margin,
            ],
          }
        : state
    const next = node.next[0]
    await this.drawNode(next, nextState)
  }

  async drawNode(index: NodeAddress, state: State): Promise<void> {
    if (this.aborted) {
      return
    }

    if (this.slow) {
      await sleep(30)
    }

    const { nodes } = this.grid
    const node = nodes[index]
    const { bb } = state
    const currentColor = node ? this.currentColor(node) : undefined
    if (node?.command) {
      this.onBeat(index, node.command, currentColor)
    }
    if (smallestDim(bb) < 2) return
    if (node === undefined) return
    node.touched = true
    const { next, command } = node
    const variables = this.lookupValues(node.variables)

    switch (command) {
      case Commands.TERMINATE:
        // do nothing
        break

      case Commands.SAME_PLACE:
        await this.drawNode(next[0], state)
        await this.drawNode(next[1], state)
        break

      case Commands.SPLIT:
        await this.decodeSplit(index, variables, state, node, false)
        break

      case Commands.FOR:
        await this.decodeSplit(index, variables, state, node, true)
        break

      case Commands.SHIFT: {
        const scale = variables[1] / MEMORY_VALUE_MAX
        const move = variables[2] / MEMORY_VALUE_MAX
        const { bb } = state

        const width = bb[2] - bb[0]
        const newWidth = width * scale
        const emptySpace = width - newWidth
        const offset = move * emptySpace

        const newState: State = {
          ...state,
          bb: [bb[0] + offset, bb[1], bb[0] + newWidth + offset, bb[3]],
        }
        await this.drawNode(next[0], newState)
        break
      }

      case Commands.AB: {
        const { bb } = state
        const width = (bb[2] - bb[0]) / 2
        const height = (bb[3] - bb[1]) / 2

        const direction = variables[1] > 127

        await this.drawNode(next[direction ? 0 : 1], {
          ...state,
          bb: [bb[0], bb[1], bb[0] + width, bb[1] + height],
        })
        await this.drawNode(next[direction ? 1 : 0], {
          ...state,
          bb: [bb[0] + width, bb[1], bb[0] + width * 2, bb[1] + height],
        })
        await this.drawNode(next[direction ? 1 : 0], {
          ...state,
          bb: [bb[0], bb[1] + height, bb[0] + width, bb[1] + height * 2],
        })
        await this.drawNode(next[direction ? 0 : 1], {
          ...state,
          bb: [
            bb[0] + width,
            bb[1] + height,
            bb[0] + width * 2,
            bb[1] + height * 2,
          ],
        })

        break
      }

      case Commands.SWITCH: {
        const firstLargest = variables[0] > variables[1] ? 0 : 1
        const firstLargestValue = variables[firstLargest]
        const nextIndex = variables[2] > firstLargestValue ? 2 : firstLargest
        await this.drawNode(next[nextIndex], state)
        break
      }

      case Commands.TERMINATE_WHEN:
        if (variables[0] > variables[1]) {
          await this.drawNode(next[1], state)
        }
        break

      case Commands.MARGIN:
        await this.margin(index, MARGIN_SPACE, node, state)
        break

      case Commands.MULTIPLY:
        await this.drawNode(next[0], state)
        break

      case Commands.INCREMENT_REGISTER_1:
        this.incrementMemory(node, 1, 0)
        await this.drawNode(next[0], state)
        break

      case Commands.DECREMENT_REGISTER_1:
        this.incrementMemory(node, -1, 0)
        await this.drawNode(next[0], state)
        break

      case Commands.INCREMENT_REGISTER_2:
        this.incrementMemory(node, 1, 1)
        await this.drawNode(next[0], state)
        break

      case Commands.DECREMENT_REGISTER_2:
        this.incrementMemory(node, -1, 1)
        await this.drawNode(next[0], state)
        break

      case Commands.INCREMENT_REGISTER_3:
        this.incrementMemory(node, 1, 2)
        await this.drawNode(next[0], state)
        break

      case Commands.DECREMENT_REGISTER_3:
        this.incrementMemory(node, -1, 2)
        await this.drawNode(next[0], state)
        break

      case Commands.LINES:
        this.drawLines(state, currentColor!, variables)
        await this.margin(index, MARGIN_LINE, node, state)
        break

      case Commands.FILL:
        rect(
          state,
          currentColor!,
          null,
          this.svg,
          null,
          variables[1] > MEMORY_VALUE_MAX / 2,
          variables[2] / MEMORY_VALUE_MAX,
          variables[0] > MEMORY_VALUE_MAX / 2 ? 'linear' : 'radial',
        )
        await this.margin(index, MARGIN_LINE, node, state)
        break

      case Commands.STROKE:
        rect(
          state,
          null,
          currentColor!,
          this.svg,
          null,
          variables[1] > MEMORY_VALUE_MAX / 2,
          variables[2] / MEMORY_VALUE_MAX,
          variables[0] > MEMORY_VALUE_MAX / 2 ? 'linear' : 'radial',
        )
        await this.margin(index, MARGIN_LINE, node, state)
        break

      case Commands.CIRCLE:
        circle(
          state,
          currentColor!,
          this.svg,
          false,
          variables[1] > MEMORY_VALUE_MAX / 2,
          variables[2] / MEMORY_VALUE_MAX,
          variables[0] > MEMORY_VALUE_MAX / 2 ? 'linear' : 'radial',
        )
        await this.margin(index, MARGIN_LINE, node, state)
        break

      case Commands.SQUARE:
        circle(
          state,
          currentColor!,
          this.svg,
          true,
          variables[1] > MEMORY_VALUE_MAX / 2,
          variables[2] / MEMORY_VALUE_MAX,
          variables[0] > MEMORY_VALUE_MAX / 2 ? 'linear' : 'radial',
        )
        await this.margin(index, MARGIN_LINE, node, state)
        break

      case Commands.CROOK:
        rect(
          state,
          currentColor!,
          null,
          this.svg,
          variables[0],
          variables[1] > MEMORY_VALUE_MAX / 2,
          variables[2] / MEMORY_VALUE_MAX,
          variables[0] > MEMORY_VALUE_MAX / 2 ? 'linear' : 'radial',
        )
        await this.margin(index, MARGIN_LINE, node, state)
        break

      case Commands.WINDOW:
        await this.drawWindow(
          index,
          state,
          node,
          variables[0] > MEMORY_VALUE_MAX / 2,
          variables[1] > MEMORY_VALUE_MAX / 2,
          variables[2] > MEMORY_VALUE_MAX / 2,
          variables[2] / MEMORY_VALUE_MAX,
          variables[0] > MEMORY_VALUE_MAX / 2 ? 'linear' : 'radial',
        )
        break

      case Commands.TRIANGLE:
        triangle(
          state,
          currentColor!,
          this.svg,
          variables[1] > 127,
          variables[2] > MEMORY_VALUE_MAX / 2,
          variables[2] / MEMORY_VALUE_MAX,
          variables[0] > MEMORY_VALUE_MAX / 2 ? 'linear' : 'radial',
        )
        await this.margin(index, MARGIN_LINE, node, state)
        break

      default:
        console.warn(`not yet implemented: ${command.toString()}`)
    }
  }

  async draw(): Promise<void> {
    if (this.aborted) return

    const initialState: State = {
      bb: viewportToBoundingBox(this.drawingSize),
    }
    this.grid.nodes.forEach((node) => {
      node.touched = false
    })
    await this.drawNode(0, initialState)
  }

  abort(): void {
    this.aborted = true
  }
}
