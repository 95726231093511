import { cloneDeep } from 'lodash'
import { Palette } from '../graphics/color.types'
import { weightedCommands } from './commands'
import Node from './Node'
import { Dimensions, Memory } from './types'
import {
  mutateStep,
  between,
  createRandomNode,
  createRandomMemory,
  initRandomPalette,
} from './helpers'

export const TERMINATOR_NODE_ADDRESS = -1
export const MEMORY_VALUE_MAX = 255

export const defaultDimensions = (): Dimensions => ({
  layers: between(24, 32),
  layerSize: between(16, 32),
  memory: between(16, 128),
  colors: between(2, 8),
})

export default class Network {
  public nodes: Node[] = []
  public memory: Memory | undefined
  public palette: Palette | undefined
  public dimensions: Dimensions | undefined

  mutate(count: number): void {
    if (!this.memory) throw new Error('no memory set')
    if (!this.palette) throw new Error('no palette set')
    if (!this.dimensions) throw new Error('no dimensions set')
    for (let i = 0; i < count; i++) {
      try {
        mutateStep(this.dimensions, this.nodes, weightedCommands(), this.memory, this.palette)
      } catch (error) {
        console.error(error)
      }
    }
  }

  constructor(networkData: Network | undefined = undefined, dimensions: Dimensions = defaultDimensions()) {
    if (networkData) {
      const clone = cloneDeep(networkData)
      this.nodes = clone.nodes
      this.memory = clone.memory
      this.palette = clone.palette
      this.dimensions = clone.dimensions
      return
    }

    if (dimensions) {
      const newNetwork = this.generateRandomNetwork(dimensions)
      this.nodes = newNetwork.nodes
      this.memory = newNetwork.memory
      this.palette = newNetwork.palette
      this.dimensions = dimensions
    }
  }

  generateRandomNetwork(dimensions: Dimensions): {
    nodes: Node[]
    memory: Memory
    palette: Palette
    dimensions: Dimensions
  } {
    let index = 0

    const nodes: Node[] = []
    for (let layer = 0; layer < dimensions.layers; layer++) {
      for (let i = 0; i < dimensions.layerSize; i++) {
        nodes[i + layer * dimensions.layerSize] = createRandomNode(
          dimensions,
          index,
          layer,
        )
        index++
      }
    }

    const memory = createRandomMemory(dimensions)
    const palette = initRandomPalette(dimensions)

    return { nodes, memory, palette, dimensions }
  }
}
