import React, { Component } from 'react'
import SVG from 'svg.js'
import Parser, { OnBeatFunction } from '../../parser/Parser'
import { sleep, viewport, Viewport } from '../../utils'
import Network from '../../cgp/Network'
import { toCSSRGB, toCSSRGBA, toCSSRGBFull } from '../../graphics/color'
import { between } from '../../cgp/helpers'
import { CommandId } from '../../cgp/commands'
import * as Commands from '../../constants/commands'
import { Color } from '../../graphics/color.types'

const margin = () => viewport().height / 10
const dotRadius = 5.5

function factoryNetwork(): Network {
  return new Network(undefined, {
    layerSize: between(6, 16),
    layers: between(32, 40),
    memory: between(8, 24),
    colors: between(5, 7),
  })
}

export default class NetworkScreen extends Component {
  private drawingElement: HTMLDivElement | undefined
  private graphElement: HTMLCanvasElement | undefined
  private paletteElement: HTMLDivElement | undefined
  private screenElement: HTMLDivElement | undefined
  private fpsElement: HTMLDivElement | undefined
  private svg: SVG.Doc | undefined
  private parser: Parser | undefined
  private colorIndicators: HTMLDivElement[] = []
  private previousNodeIndex = -1
  private destroyed = false
  private drawing: Network | undefined = undefined // factoryNetwork()
  private timeoutId: NodeJS.Timeout | undefined
  private didStart = false
  private ctx: CanvasRenderingContext2D | undefined
  private lastDrawnIndex = -1
  private nextDrawIndex = -1
  private nextCommandId: number | undefined = -1
  private nextColor: Color | undefined
  private startTime: number | undefined
  private frameCounter = 0

  async run(): Promise<void> {
    if (this.drawingElement === undefined || this.svg === undefined) return
    this.startTime = +new Date()
    this.frameCounter = 0
    this.destroyed = true

    if (this.screenElement) {
      this.screenElement.style.opacity = '0'
      this.screenElement.style.boxShadow = ''
      this.screenElement.style.background = 'rgba(40,40,40,1)'
      document.getElementsByTagName('svg')[0].style.backgroundColor = 'rgba(40,40,40,1)'
    }

    await sleep(2000)
    this.svg.clear()
    this.ctx!.clearRect(0, 0, this.graphElement!.width, this.graphElement!.height)
    if (this.screenElement) {
      this.screenElement.style.opacity = '1'
    }
    const size = viewport().width - margin() * 2

    const drawingSize: Viewport = {
      width: size,
      height: size,
    }

    this.svg.size(drawingSize.width, drawingSize.height)
    this.drawing = factoryNetwork()

    this.paletteElement!.innerHTML = ''
    for (let i = 0; i < this.drawing.palette!.length; i++) {
      const color = document.createElement('div')
      this.colorIndicators[i] = color
      this.paletteElement!.appendChild(color)
    }
    await sleep(2000)
    this.parser = new Parser(this.drawing, this.svg, drawingSize)
    this.parser.onBeat = this.handleOnBeatBuffered
    this.parser.draw() // eslint-disable-line @typescript-eslint/no-floating-promises
    this.destroyed = false
    this.drawFrame()
  }

  odd = false

  drawFrame = (): void => {
    const ctx = this.ctx!
    const canvas = this.graphElement!
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    for (let i = 0; i < imageData.data.length / 8; i++) {
      const index = i * 2 + (this.odd ? 0 : 1)
      imageData.data[index * 4 + 3] = imageData.data[index * 4 + 3] * 0.95
    }
    ctx.putImageData(imageData, 0, 0)

    if (this.nextDrawIndex !== this.lastDrawnIndex) {
      this.updateNetwork(this.nextDrawIndex, this.nextCommandId, this.nextColor)
      this.lastDrawnIndex = this.nextDrawIndex
    }
    this.odd = !this.odd

    this.updateFPS()
    if (!this.destroyed) {
      requestAnimationFrame(this.drawFrame)
    }
  }

  handleOnBeatBuffered: OnBeatFunction = (index: number, command: CommandId | undefined, color: Color | undefined) => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }

    this.timeoutId = setTimeout(() => {
      this.run() // eslint-disable-line 
    }, 5000)
    this.nextDrawIndex = index
    this.nextCommandId = command
    this.nextColor = color
  }

  async init(): Promise<void> {
    if (
      !this.drawingElement ||
      !this.graphElement ||
      !this.paletteElement ||
      this.didStart
    ) {
      return
    }
    this.didStart = true
    await this.run()
  }

  setScreen = (screenElement: HTMLDivElement): void => {
    if (screenElement === undefined || this.screenElement !== undefined) {
      return
    }
    this.screenElement = screenElement
  }

  initDrawing = async (drawingElement: HTMLDivElement): Promise<void> => {
    if (drawingElement === undefined || this.drawingElement !== undefined) {
      return
    }
    this.drawingElement = drawingElement
    this.svg = SVG(this.drawingElement)
    await this.init()
  }

  c(index: number, alpha: number): string {
    if (!this.parser) return ''
    const color = this.parser.colorByPaletteIndex(index)
    const string = toCSSRGBA([color[0], color[1], color[2], alpha])
    return string
  }

  backgroundGradientString(): string {
    return `linear-gradient(0deg, ${this.c(2, 64)} 0%, ${this.c(3, 64)} 50%, ${this.c(4, 64)} 100%)`
  }

  updatePalette(): void {
    for (let i = 0; i < this.drawing!.palette!.length; i++) {
      const color = this.parser!.colorByPaletteIndex(i)
      this.colorIndicators[i].style.backgroundColor = toCSSRGB(color)
    }

    if (this.screenElement) {
      this.screenElement.style.background = this.backgroundGradientString()
      const b = this.parser!.colorByPaletteIndex(1)
      const boxShadow = `${toCSSRGBA([b[0], b[1], b[2], 127])} 0px 0px 20vw inset`
      this.screenElement.style.boxShadow = boxShadow
    }

    const svg = document.getElementsByTagName('svg')[0]
    if (svg && this.parser) {
      const s = this.parser.colorByPaletteIndex(2)
      svg.style.backgroundColor = toCSSRGBA([s[0], s[1], s[2], 64])
    }
  }

  nodeCoords(nodeIndex: number): [number, number] {
    const layerSize = this.drawing!.dimensions!.layerSize

    return [
      nodeIndex % layerSize,
      (nodeIndex / layerSize) << 0,
    ]
  }

  updateNetwork = (nodeIndex: number, commandId: CommandId | undefined, color: Color | undefined): void => {
    const ctx = this.ctx!
    const dims = [this.graphElement!.width, this.graphElement!.height]

    const width = (dims[0] - dotRadius * 2) / (this.drawing!.dimensions!.layerSize - 1)
    const height = (dims[1] - dotRadius * 2) / (this.drawing!.dimensions!.layers - 1)
    const m: [number, number] = [width, height]
    const centerX = dotRadius // dims[0] / 4

    const prevCoords = trans(mul(this.nodeCoords(this.previousNodeIndex), m), [centerX, dotRadius])
    const nextCoords = trans(mul(this.nodeCoords(nodeIndex), m), [centerX, dotRadius])

    if (nodeIndex === 0) {
      nextCoords[0] = this.graphElement!.width / 2
    }

    if (this.previousNodeIndex === 0) {
      prevCoords[0] = this.graphElement!.width / 2
    }

    ctx.lineWidth = 5
    if (nodeIndex > this.previousNodeIndex) {
      if (commandId === Commands.FILL ||
        commandId === Commands.TRIANGLE ||
        commandId === Commands.DOTTED_STROKE ||
        commandId === Commands.STROKE ||
        commandId === Commands.CROOK ||
        commandId === Commands.SQUARE ||
        commandId === Commands.WINDOW ||
        commandId === Commands.LINES ||
        commandId === Commands.CIRCLE
      ) {
        const colorString = color ? toCSSRGBFull(color) : 'white'
        ctx.strokeStyle = colorString
        ctx.fillStyle = colorString
      }
    } else {
      ctx.strokeStyle = 'rgba(0,0,0,0.25)'
    }
    ctx.beginPath()
    ctx.moveTo(...prevCoords)
    if (nodeIndex !== 0) {
      ctx.lineTo(...nextCoords)
    }

    ctx.stroke()
    ctx.beginPath()
    ctx.arc(...nextCoords, dotRadius, 0, 2 * Math.PI, true)
    ctx.fill()
    this.previousNodeIndex = nodeIndex
    this.updatePalette()
  }

  initGraph = async (graphElement: HTMLCanvasElement): Promise<void> => {
    if (graphElement === undefined || this.graphElement !== undefined) {
      return
    }
    this.graphElement = graphElement
    const size = 4
    const width = viewport().width / size
    const height = viewport().height - width * size
    graphElement.width = width * window.devicePixelRatio
    graphElement.height = height * window.devicePixelRatio
    graphElement.style.width = `${width}px`
    graphElement.style.height = `${height}px`
    this.ctx = graphElement.getContext('2d')!
    await this.init()
  }

  initPalette = async (paletteElement: HTMLDivElement): Promise<void> => {
    if (paletteElement === undefined || this.paletteElement !== undefined) {
      return
    }

    this.paletteElement = paletteElement

    await this.init()
  }

  updateFPS(): void {
    if (!this.fpsElement || !this.startTime) return
    const timepassed = +new Date() - this.startTime

    this.fpsElement.innerHTML = `${1000 / (timepassed / this.frameCounter) << 0}`
    this.frameCounter++
  }

  initFpsElement = (fpsElement: HTMLDivElement): void => {
    if (fpsElement) {
      this.fpsElement = fpsElement
    }
  }

  render(): JSX.Element {
    return (
      <>
        <div className='network-screen' ref={this.setScreen}>
          <div ref={this.initPalette} className='network-palette'></div>
          <canvas ref={this.initGraph} className='network-graph'></canvas>
          <div ref={this.initDrawing} className='network-screen-drawing' />
        </div>
        <div className='fps' ref={this.initFpsElement}>?</div>
      </>
    )
  }
}

function mul(m: [number, number], n: [number, number]): [number, number] {
  return [m[0] * n[0], m[1] * n[1]]
}

function trans(m: [number, number], n: [number, number]): [number, number] {
  return [
    m[0] + n[0],
    m[1] + n[1],
  ]
}
